<template>
  <div class="sign-in">
    <div class="container">
      <h4 class="text-center title mb-8">You have been invited to a team!</h4>
      <v-form ref="form" v-model="valid" lazy-validation @submit="onSubmit">
        <div class="text-center mt-8">
          <v-btn type="submit" color="primary">Accept Invite</v-btn>
        </div>
      </v-form>
    </div>
  </div>
</template>

<style scoped lang="scss">
.errors ul {
  margin: auto;
  li {
    list-style-type: none;
  }
}
.container {
  max-width: 500px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.19);
  padding: 25px;
  margin: 30px auto;
  background-color: white;
}
</style>

<script>
import ACTIVATE_USER_TEAM from "../graphql/ActivateUserTeam.gql";
import router from "../router";

export default {
  name: "ActivateUserTeam",
  data() {
    return {
      errors: [],
      inviteToken: null,
      valid: null
    };
  },
  methods: {
    async onSubmit(evt) {
      evt.preventDefault();
      const isValid = this.$refs.form.validate();

      console.log("isValid", isValid);
      if (!isValid) {
        return;
      }
      const inviteToken = this.$route.query.inviteToken ? this.$route.query.inviteToken : null;
      const accountId = this.$route.query.accountId ? this.$route.query.accountId : null;

      if (!inviteToken) {
        alert("A token is required");
        return;
      }
      this.errors = [];
      try {
        const activateResult = await this.$apollo.mutate({
          mutation: ACTIVATE_USER_TEAM,
          variables: {
            accountId: accountId,
            inviteToken
          }
        });
        this.$store.dispatch("auth/signin", {
          authUser: activateResult.data.activateUserTeam
        });
        router.push({ name: "course-list" });
      } catch (error) {
        this.errors.push(error.toString().replace("Error: GraphQL error: ", "")); // Vue doesn't like non-native types as keys, hence string
        this.$store.dispatch("auth/signin", { authUser: null });
      }
    }
  }
};
</script>
